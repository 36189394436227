import { useRef } from "react";
import getNav from "@sussex/nav-renderer";
import { getFallbackLanguage } from "../providers/CopyProvider";
import { actions, useAppStateContext } from "../providers/AppStateProvider";

// Temporary workaround for bug in nav-renderer.
// When fixed, use getNavAsync, which doesn't require including the navDefinition.
const getNavDefintion = async env => {
  const baseUrls = {
    dev: "https://d2yovri7aiwgx3.cloudfront.net",
    qa02: "https://d10f2sfhyq4iy8.cloudfront.net",
    qa03: "https://d2jsxzv58u9j7z.cloudfront.net",
    prod: "https://d3r3iytm0122ax.cloudfront.net",
  };
  const navUrl = `${baseUrls[env]}/member-nav.json`;
  const response = await fetch(navUrl);
  const navDefinition = await response.json();

  return navDefinition;
};

const _getNav = async (path, language, country) => {
  const env = process.env.REACT_APP_ENVIRONMENT;
  const runtime = process.env.REACT_APP_RUNTIME;
  const navDefinition = await getNavDefintion(runtime === "qa" ? env : runtime);
  const result = await getNav({
    env,
    path,
    nav: "member-nav",
    brand: "psychologytoday",
    viewingContext: "psychologytoday",
    viewingCountryCode: country,
    viewingLanguageCode: language,
    profileHealthRole: "THERAPIST",
    profileStatus: "ENABLED",
    hasSessions: true,
    navDefinition,
  });

  return result[0];
};
export default async function useNav() {
  const { state, dispatch } = useAppStateContext();
  const { SET_MAIN_NAV, SET_ACCOUNT_NAV } = actions;
  const { mainNav } = state;
  const fetching = useRef(false);

  if (fetching.current || mainNav !== null) {
    return;
  }

  const [language, country] = navigator.language.split("-");

  try {
    fetching.current = true;
    const mainNavResult = await _getNav("main", language, country);
    dispatch({ type: SET_MAIN_NAV, nav: mainNavResult });
    const accountResult = await _getNav("account", language, country);
    dispatch({ type: SET_ACCOUNT_NAV, nav: accountResult });
    fetching.current = false;
  } catch (e) {
    /**
     * Most likely the language doesn't exist. Fetch nav in the default language
     */
    try {
      const [defaultLanguage, defaultCountry] = getFallbackLanguage(
        navigator.language,
      ).split("-");
      const mainNavResult = await _getNav(
        "main",
        defaultLanguage,
        defaultCountry,
      );
      dispatch({ type: SET_MAIN_NAV, nav: mainNavResult });
      const accountResult = await _getNav(
        "account",
        defaultLanguage,
        defaultCountry,
      );
      dispatch({ type: SET_ACCOUNT_NAV, nav: accountResult });
      fetching.current = false;
    } catch (e) {
      fetching.current = false;
    }
  }
}

import React, { useCallback, useRef, useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import styled, { css } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { checkElementOverlap } from "@sussex/react-kit/utils";
import CheckinArea from "./CheckinArea";
import ConnectionDroppedHandler from "../components/ConnectionDroppedHandler";
import { VideoProvider } from "../providers/VideoProvider";
import { SessionMenuProvider } from "../providers/SessionMenuProvider";
import Websocket from "../components/Websocket";
import {
  WaitingRoomProvider,
  useWaitingRoomContext,
} from "../providers/WaitingRoomProvider";
import BackgroundImage from "../components/BackgroundImage";
import WaitingRoomDetails from "../components/WaitingRoomDetails";
import LocalVideoPreview from "../components/LocalVideoPreview/LocalVideoPreview";
import Header from "../components/Header";
import PhotoCanvas from "../components/PhotoCanvas";
import { useAppStateContext } from "../providers/AppStateProvider";
import Panel from "../components/Panel";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../hooks/useCopy";
import Session from "../pages/SessionView";
import VideoAndMicSettings from "../components/VideoAndMicSettings";
import { DevicesProvider } from "../providers/DevicesProvider";
import { AudioAlertProvider } from "../providers/AudioAlertProvider";
import logo from "../assets/pt-sessions-logo-center.svg";
import SlideoutLayout from "../components/SlideoutLayout";
import { ChatProvider, WaitingRoomChat } from "../components/Chat";
import EventBus from "../eventBus";
import chatAvailable from "../assets/chat-available.svg";
import closeNotification from "../assets/close-notification-icon.svg";
import { useChatContext } from "../components/Chat/ChatProvider";
import { datadogSetPage, datadogSetUserType, datadogEvent } from "../datadog";
import AppInstallBanner from "../components/AppInstallBanner";

const PageWrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  margin: 0 15px;
  @media (min-width: 768px) {
    box-sizing: border-box;
    width: 100%;
    max-width: 1325px;
    margin: 0 auto;
    ${({ fixedFooter }) =>
      fixedFooter &&
      css`
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        transform: translateY(-50%);
      `};
  }
`;

const WrappedPanel = styled(Panel)`
  @media (min-width: 768px) {
    margin: 0 50px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > * {
    width: 100%;
  }
  @media (min-width: 768px) {
    box-sizing: border-box;
    flex-direction: row;
  }
`;

const Item = styled.div`
  width: 100%;
  position: relative;
  @media (min-width: 768px) {
    margin: 0 20px 0 0;
    flex: 1 1 ${({ basis }) => basis};
    max-width: 700px;
    &:last-child {
      margin: 0;
    }
  }
`;

const Notification = styled(Snackbar)`
  font-size: ${({ theme }) => theme.fontSize.small};
  &.MuiSnackbar-root {
    opacity: 0.9;
    top: inherit;
    bottom: 0;
    @media (min-width: 768px) {
      right: 0;
      top: 0;
      bottom: inherit;
    }
  }
  & .MuiSnackbarContent-root {
    border-radius: 3px 3px 0 0;
    padding: 0;
    width: 345px;
    @media (min-width: 768px) {
      border-radius: 0 0 0 3px;
    }
  }
  & .MuiSnackbarContent-message {
    width: 100%;
    padding: 15px;
  }
`;

const NewMessageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const NewMessageImageWrapper = styled.div`
  margin-right: 15px;
`;

const NewMessageTextWrapper = styled.div`
  margin-right: 15px;
  flex-grow: 1;
  cursor: pointer;
`;

const NewMessageCTAWrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const NewMessageCloseWrapper = styled.div`
  cursor: pointer;
`;

const NewMessage = ({ onClose, onClick }) => {
  const {
    room: { therapistDisplayName },
  } = useWaitingRoomContext();
  const [therapistAvailable, sendMessage, closeBtn] = useCopy([
    "chat.client.therapistAvailable",
    "chat.client.sendMessage",
    "chat.client.closeBtn",
  ]);
  const status = swapCopyVariables(therapistAvailable, {
    NAME: therapistDisplayName,
  });
  return (
    <NewMessageWrapper>
      <NewMessageImageWrapper>
        <img src={chatAvailable} alt={status} />
      </NewMessageImageWrapper>
      <NewMessageTextWrapper onClick={onClick}>
        <div>{status}</div>
        <NewMessageCTAWrapper>{sendMessage}</NewMessageCTAWrapper>
      </NewMessageTextWrapper>
      <NewMessageCloseWrapper onClick={onClose}>
        <img src={closeNotification} alt={closeBtn} />
      </NewMessageCloseWrapper>
    </NewMessageWrapper>
  );
};

const Footer = styled.div`
  color: white;
  background: linear-gradient(
    0deg,
    rgba(45, 45, 48, 0.75) 0%,
    rgba(45, 45, 48, 0) 100%
  );
  z-index: 8;
  text-align: center;
  padding: 40px 20px 20px;

  @media (min-width: 768px) {
    ${({ fixed }) =>
      fixed &&
      css`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
      `};
  }
`;

const Providers = ({ children }) => (
  <ChatProvider eventBus={EventBus}>
    <WaitingRoomProvider>
      <WaitingRoomDetails type="client">
        <VideoProvider>
          <DevicesProvider>
            <ConnectionDroppedHandler isTherapist={false}>
              <AudioAlertProvider>
                <SessionMenuProvider>{children}</SessionMenuProvider>
              </AudioAlertProvider>
            </ConnectionDroppedHandler>
          </DevicesProvider>
        </VideoProvider>
      </WaitingRoomDetails>
    </WaitingRoomProvider>
  </ChatProvider>
);

const View = () => {
  const history = useHistory();
  const { roomAlias } = useParams();
  const mobile = !useMediaQuery("(min-width:768px)");

  const chatHasOpened = useRef(false);
  const canvasRef = useRef(null);
  const bodyRef = useRef(null);
  const footerRef = useRef(null);

  const [notificationOpen, setNotificationOpen] = useState(false);
  const [video, setVideo] = useState(null);
  const [videoError, setVideoError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [changingMediaSettings, setChangingMediaSettings] = useState(false);
  const { activeChannel, setActiveChannel, getUnreadCount } = useChatContext();
  const [fixedFooter, setFixedFooter] = useState(true);

  const {
    state,
    state: { therapist },
    setClientWaitingRoomChatOpen,
  } = useAppStateContext();
  const { websocket, session } = state;
  const unreadChatMessages = getUnreadCount(therapist.participantId);

  const [logoText] = useCopy(["callscreen.poweredby"]);

  // NOTE: LEAVING THIS CODE HERE BECAUSE IT WILL BE THE BASE
  // FOR THE NEW MESSAGE NOTIFICATION THAT WE WILL IMPLEMENT
  // IN THE NEAR FUTURE.
  /*
  useEffect(() => {
    if (state.websocket.isConnected) {
      if (
        state.waitingRoom.therapistAvailable &&
        state.waitingRoom.therapistInitialState === false
      ) {
        setNotificationOpen(true);
      }
    }
  }, [
    state.waitingRoom.therapistAvailable,
    state.waitingRoom.therapistInitialState,
    state.websocket.isConnected,
  ]);
  */

  const reset = () => {
    setVideoError(false);
  };

  const onVideoError = () => {
    setVideoError(true);
  };

  const onConnectionError = () => {
    setConnectionError(true);
  };

  const toggleMediaSettings = active => {
    setChangingMediaSettings(active);

    datadogEvent({
      category: "media_settings",
      feature: "panel",
      event: active ? "opened" : "closed",
      component: "ClientRoomView",
    });
  };

  const onSessionEnd = useCallback(
    (room, error) => {
      if (!error) {
        history.push(`/${roomAlias}/complete`);
      }
    },
    [history, roomAlias],
  );

  useEffect(() => {
    datadogSetUserType("client");
    datadogSetPage("WaitingRoom");
  }, []);

  useEffect(() => {
    const body = bodyRef && bodyRef.current;
    const footer = footerRef.current;
    if (!body || !footer) return;
    const checkFooterOverlap = () => {
      setFixedFooter(true);
      setFixedFooter(!checkElementOverlap(body, footer));
    };
    checkFooterOverlap();
    window.addEventListener("resize", checkFooterOverlap);
    return () => {
      window.removeEventListener("resize", checkFooterOverlap);
    };
  }, []);

  // Open the chat panel on first chat message if the client has not already
  // opened the chat panel and the settings panel is not open.
  // Non-mobile view only.
  useEffect(() => {
    if (activeChannel) {
      chatHasOpened.current = true;
    }
  }, [activeChannel]);

  useEffect(() => {
    if (mobile) return;
    if (chatHasOpened.current) return;
    if (!unreadChatMessages) return;
    if (changingMediaSettings) {
      chatHasOpened.current = true;
      return;
    }
    setActiveChannel(therapist.participantId);
  }, [
    unreadChatMessages,
    therapist.participantId,
    setActiveChannel,
    mobile,
    changingMediaSettings,
  ]);

  return session.active ? (
    <Session
      token={session.token}
      playNotification={true}
      isTherapist={false}
      onSessionEnd={onSessionEnd}
    />
  ) : (
    <PageWrapper>
      <Notification
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={() => setNotificationOpen(false)}
        message={
          <NewMessage
            onClose={() => setNotificationOpen(false)}
            onClick={() => {
              setActiveChannel(therapist.participantId);
              setClientWaitingRoomChatOpen(true);
              setNotificationOpen(false);
            }}
          />
        }
      />
      <Websocket />
      <BackgroundImage />
      <Header type="client" />
      <Wrapper ref={bodyRef} fixedFooter={fixedFooter}>
        <WrappedPanel>
          <Body>
            <Item basis="70%">
              {mobile && (
                <CheckinArea
                  websocketError={websocket.error}
                  isConnected={websocket.isConnected}
                  video={video}
                  canvas={canvasRef}
                  connectionError={connectionError}
                  videoError={videoError}
                  onVideoError={onVideoError}
                  onConnectionError={onConnectionError}
                  onReset={reset}
                  onSettingsOpen={() => toggleMediaSettings(true)}
                />
              )}
              {!mobile && (
                <>
                  <LocalVideoPreview
                    setVideo={setVideo}
                    showMediaControls={websocket.isConnected}
                    onMediaSettingsOpen={() =>
                      toggleMediaSettings(!changingMediaSettings)
                    }
                    isTherapist={false}
                  />
                  <PhotoCanvas canvasRef={canvasRef} />
                </>
              )}
            </Item>
            <Item basis="30%">
              {mobile && (
                <>
                  <LocalVideoPreview
                    setVideo={setVideo}
                    showMediaControls={websocket.isConnected}
                    onMediaSettingsOpen={() =>
                      toggleMediaSettings(!changingMediaSettings)
                    }
                    isTherapist={false}
                  />
                  <PhotoCanvas canvasRef={canvasRef} />
                </>
              )}
              {!mobile && (
                <CheckinArea
                  websocketError={websocket.error}
                  isConnected={websocket.isConnected}
                  video={video}
                  canvas={canvasRef}
                  connectionError={connectionError}
                  videoError={videoError}
                  onVideoError={onVideoError}
                  onConnectionError={onConnectionError}
                  onReset={reset}
                  onSettingsOpen={() => toggleMediaSettings(true)}
                />
              )}
            </Item>
          </Body>
        </WrappedPanel>
      </Wrapper>
      <Footer ref={footerRef} fixed={fixedFooter}>
        <a href="/">
          <img src={logo} alt={logoText} />
        </a>
      </Footer>
      <VideoAndMicSettings
        active={changingMediaSettings}
        onClose={() => toggleMediaSettings(false)}
        overlap={true}
        testsEnabled={true}
      />
      <WaitingRoomChat
        isTherapist={false}
        participant={therapist}
        channel={therapist.participantId}
        position="right"
        overlap={true}
      />
      <AppInstallBanner app="sessions" />
    </PageWrapper>
  );
};

export default function ClientRoomView() {
  return (
    <Providers>
      <SlideoutLayout>
        <View />
      </SlideoutLayout>
    </Providers>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import CookieBot from "react-cookiebot";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import ourTheme from "./theme";
import { datadogInit } from "./datadog";

const cookiebotGroupId = "332cddcd-264e-4005-a7e9-ca8d4ecbb87f";

datadogInit();

ReactDOM.render(
  <>
    <React.StrictMode>
      <ThemeProvider theme={ourTheme}>
        <Normalize />
        <Router />
      </ThemeProvider>
    </React.StrictMode>
    <CookieBot domainGroupId={cookiebotGroupId} />
  </>,
  document.getElementById("root"),
);

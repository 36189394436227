import React, { useState } from "react";
import styled from "styled-components";
import Panel from "../Panel";
import { TextArea, Button, SubmitButton, H1 } from "@sussex/react-kit/elements";
import { requestSendFeedback } from "../../httpapi";
import useCopy from "../../hooks/useCopy";
import { datadogEvent } from "../../datadog";
import AppStoreLinks from "../AppStoreLinks";

const Header = styled.div`
  margin-bottom: 20px;
`;

const FormWrapper = styled.form`
  display: block;
`;

const InputWrapper = styled.div`
  grid-column: span 2;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    grid-column: 2 / span 1;
  }
`;

const FormItemWrapper = styled.div`
  margin-bottom: 20px;
`;

const Question = styled.p`
  font-size: 16px;
  margin: 0 0 10px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  > * {
    width: calc(50% - 5px);
  }

  @media (min-width: 768px) {
    > * {
      width: 160px;
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: 13px;
  line-height: 2;
  margin: 0;
  color: ${({ theme }) => theme.colors.red};
`;

export default function Survey({ sessionId, onSurveyEnd }) {
  const [error, setError] = useState(null);
  const [busy, setBusy] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [thanks, title, question, btnReturn, btnSubmit, errorMsg] = useCopy([
    "survey.thanks",
    "survey.title",
    "survey.question",
    "survey.btnReturn",
    "survey.btnSubmit",
    "survey.errorMsg",
  ]);

  const handleFeedbackChange = e => setFeedback(e.target.value);

  const handleReturn = async e => {
    e.preventDefault();

    datadogEvent({
      category: "session_feedback",
      feature: "return",
      event: "clicked",
      component: "Survey",
    });

    onSurveyEnd();
  };

  const handleSubmit = async e => {
    e.preventDefault();

    datadogEvent({
      category: "session_feedback",
      feature: "send",
      event: "clicked",
      component: "Survey",
    });

    setBusy(true);
    setError(null);

    try {
      await requestSendFeedback(sessionId, feedback);

      datadogEvent({
        category: "session_feedback",
        event: "sent",
        component: "Survey",
      });

      onSurveyEnd(thanks);
    } catch (err) {
      setError(err);
      console.error("Error Sending Feedback", err);
      setBusy(false);
    }
  };

  return (
    <Panel>
      <FormWrapper onSubmit={handleSubmit}>
        <Header>
          <H1>{title}</H1>
        </Header>
        <InputWrapper>
          <FormItemWrapper>
            <Question>{question}</Question>
            <TextArea
              onChange={handleFeedbackChange}
              maxLength="1000"
              rows="8"
            />
            {error && <ErrorMessage>{errorMsg}</ErrorMessage>}
          </FormItemWrapper>
          <Actions>
            <Button id="surveyReturn" onClick={handleReturn} variant="white">
              {btnReturn}
            </Button>
            <SubmitButton id="surveySubmit" disabled={!feedback.trim() || busy}>
              {btnSubmit}
            </SubmitButton>
          </Actions>
        </InputWrapper>
        <AppStoreLinks app="professionals" />
      </FormWrapper>
    </Panel>
  );
}

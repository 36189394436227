import React from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "@material-ui/core";

const StyledIconButton = styled.button`
  display: block;
  width: 100%;
  padding: 100% 0 0;
  border-radius: 50%;
  background: ${({ background, theme }) =>
    background || theme.colors.transparentGreyBackground};
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-sizing: content-box;
  box-shadow: 0 0 3px 0 rgba(45, 45, 48, 0.4);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 0;
  &:hover,
  &:active {
    background: ${({ disabled, background, theme }) =>
      !disabled && !background
        ? theme.colors.transparentGreyBackgroundHover
        : background};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    pointer-events: none;
  }
`;

const IconButton = ({ tooltip, ...rest }) => {
  if (!tooltip) {
    return <StyledIconButton {...rest} />;
  }

  return (
    <Tooltip title={tooltip}>
      <div>
        <StyledIconButton {...rest} />
      </div>
    </Tooltip>
  );
};

export default IconButton;
